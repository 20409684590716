#chat::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f5f5;
}

#chat::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: 1px;
  width: 7px;
}

#chat::-webkit-scrollbar-track {
  background-color: #dedede;
}

.arrow-left {
  width: 0; 
  height: 0; 
  border-top: 3px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 7px solid #2196f3;
  margin-top: 5px;
  position: absolute;
  right: -6px;
  top: 0
}

.arrow-right {
  width: 0; 
  height: 0; 
  border-top: 3px solid transparent;
  border-bottom: 5px solid transparent; 
  border-right: 7px solid #c1c0c0;
  margin-top: 5px;
  position: absolute;
  left: -6px;
  top: 0
}